import React from "react";
import ModalVideo from "react-modal-video";
const playIcon = require("/images/play-icon.svg");

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
    window._trackEvent('video', 'view', 'modals');
  }

  render() {
    return (
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="96Knj5Y6lXU"
          onClose={() => this.setState({ isOpen: false })}
        />
        <a onClick={this.openModal} className="hero__video-cta action-button" href="#">
          Watch the video &nbsp;
          <img className="hero__video-cta-icon" src={playIcon} />
        </a>
      </div>
    );
  }
}

export default Modal;

import React from "react";

import "whatwg-fetch";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewContract: null
    };
  }

  doValidate(e) {
    window._trackEvent('form', 'attempt');

    e.preventDefault();
    if (!this._validate()) {
      window._trackEvent('form', 'validate', 'fail');
      return false;
    }
    this.doShare();
  }

  doShare() {
    if (!this._validate()) {
      return false;
    }
    const yourName = this.refs.your_name.value;
    const theirName = this.refs.their_name.value;

    var formData = new FormData();
    formData.append("your_name", yourName);
    formData.append("their_name", theirName);

    fetch("/add/", {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .catch(error => console.error("Error:", error))
      .then(response => {
        window._trackEvent('form', 'created');
        if (response.id) {
          window.location.href = "/~" + response.id;
          return;
        }

        const yourName = this.refs.your_name.value;
        const theirName = this.refs.their_name.value;
        const date = new Date();

        const c = {
          yourName,
          theirName,
          date
        };

        window.contractOpen(c);
      });

    return false;
  }

  onPreview(e) {
    e.preventDefault();
    if (!this._validate()) {
      return false;
    }

    const yourName = this.refs.your_name.value;
    const theirName = this.refs.their_name.value;
    const date = new Date();

    const c = {
      yourName,
      theirName,
      date
    };

    window.contractPreview(c);

    return false;
  }

  _validate() {
    if (this.refs.your_name.value == "") {
      toast.error("Your name is empty.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.refs.your_name.focus();
      return false;
    }
    if (this.refs.their_name.value == "") {
      toast.error("Their name is empty.", {
        position: toast.POSITION.BOTTOM_CENTER
      });
      this.refs.their_name.focus();
      return false;
    }
    return true;
  }

  onCallback() { }

  render() {
    return (
      <div className="form__container">
        <div className="form__inputs">
          <input
            className="form__input"
            name="your_name"
            ref="your_name"
            defaultValue=""
            placeholder="Your Full Name"
          />
          <input
            className="form__input"
            name="their_name"
            ref="their_name"
            defaultValue=""
            placeholder="Young Voter's Name"
          />
        </div>
        <div className="actions">
          {/* <a
            className="action__preview"
            href="#"
            onClick={this.onPreview.bind(this)}
          >
            Preview
          </a> */}
          <button
            className="action__share"
            onClick={this.doValidate.bind(this)}
          >
            Share deal
          </button>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
      </div>
    );
  }
}

export default Form;

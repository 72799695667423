import React from "react";
import ReactDOM from "react-dom";

import Modal from "./modal";
import Form from "./form";
import Contract from "./contract";

const contract = <Contract />;
ReactDOM.render(contract, document.getElementById("contract"));
ReactDOM.render(<Modal />, document.getElementById("hero"));
ReactDOM.render(
  <Form previewContract={contract} />,
  document.getElementById("form")
);

// Quick and dirty, making cards clickable.
let resourceCards = document.querySelectorAll('.resources__card');
resourceCards.forEach(function (card) {
  card.addEventListener('click', function (e) {
    let link = card.querySelector('.resources__action');
    let destination = link.getAttribute('href');
    let win = window.open(destination, '_blank');

    window._trackEvent('links', 'cards', link.getAttribute('id'))
  });
});
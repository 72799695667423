import React from "react";
import ReactDOM from "react-dom";

const contractSource = require("../images/contract.png");
const facebookShareImage = require("../images/share-fb-blue.svg");
const twitterShareImage = require("../images/share-twitter-blue.svg");
const copyShareImage = require("../images/share-copy-blue.svg");

class Contract extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      contract: {}
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.preview = this.preview.bind(this);

    window.contractPreview = function (contract) {
      this.preview(contract);
    }.bind(this);

    window.contractOpen = function (contract) {
      this.open(contract);
    }.bind(this);
  }

  componentDidMount() {
    //console.log("contract componentDidMount");
    //console.log(window);
    if (window.linkedContract) {
      this.open(window.linkedContract);
    }
  }

  open(contract) {
    this.setState({ showModal: true });
    const element = ReactDOM.findDOMNode(this);
    element.parentNode.classList.add("active");

    this.setState({
      contract: contract
    });

    window._trackEvent('contract', 'view')
  }

  close() {
    this.setState({ showModal: false });
    const element = ReactDOM.findDOMNode(this);
    element.parentNode.classList.remove("active");

    history.pushState(this.state, window.title, "/");

    window._trackEvent('contract', 'close')
  }

  copy(e) {
    // There is a new clipboard API but the coverage
    // is meh, so this is what I did instead. It sucks.
    e.preventDefault();
    let hiddenInput = document.createElement('textarea');
    hiddenInput.value = window.location.href;
    hiddenInput.setAttribute('readonly', '');
    hiddenInput.style = { position: 'absolute', left: '-99999px' };
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenInput);

    window._trackEvent('contract', 'social', 'copy');
  }

  preview(contract) {
    this.setState({ showModal: true });
    const element = ReactDOM.findDOMNode(this);
    element.parentNode.classList.add("active");

    this.setState({
      contract: contract
    });
  }

  render() {
    const { date } = this.state.contract;

    let dateString = "";
    if (date) {
      var mm = date.getMonth() + 1;
      var dd = date.getDate();
      var yyyy = date.getFullYear();
      dateString = mm + "." + dd + "." + yyyy;
    }

    var twitterShareCopy =
      "@US_Vote Hey (TAG YOUR FAMILY MEMBER HERE) let’s make a Big Deal. The midterms are Nov 6. If you post a picture proving you voted, I’ll never comment on your posts ever again. What do you think? #TheBigDeal2018";
    const share_twitter_url =
      "https://twitter.com/home?status=" +
      encodeURIComponent(twitterShareCopy + window.location.href);

    const share_fb_url =
      "https://www.facebook.com/sharer/sharer.php?u=" +
      encodeURIComponent(window.location.href);

    return (
      <div className="contract__container">
        <div className="contract__bg" onClick={this.close} />
        <div className="contract__nav">
          <ul className="social">
            <li>
              <div className="contract__social">
                <a
                  className="social__link social__link--fb"
                  id="share-fb"
                  target="_blank"
                  href={share_fb_url}
                  title="Share to Facebook"
                  onclick="window._trackEvent('contract', 'social', 'facebook')"
                >
                  <img src={facebookShareImage} alt="Facebook" />
                </a>
                <a
                  className="social__link social__link--twitter"
                  id="share-twitter"
                  target="_blank"
                  href={share_twitter_url}
                  title="Share to Twitter"
                  onclick="window._trackEvent('contract', 'social', 'twitter')"
                >
                  <img src={twitterShareImage} alt="Twitter" />
                </a>

                <a
                  className="social__link social__link--copy"
                  id="share-copy"
                  href="#"
                  title="Copy to clipboard"
                  onClick={this.copy}
                >
                  <img src={copyShareImage} alt="Twitter" />
                </a>
              </div>
            </li>
          </ul>
          <button
            className="action-button action-button--white contract__close"
            onClick={this.close}
          >
            Close
          </button>
        </div>
        <div className="contract__content">
          <img src={contractSource} className="contract__image" />
          <div className="contract__fields">
            <div className="contract__field contract__yourname">
              {this.state.contract.yourName}
            </div>
            <div className="contract__field contract__theirname">
              {this.state.contract.theirName}
            </div>
            <div className="contract__field contract__datefield">
              {dateString}
            </div>
            <div className="contract__field contract__signature">
              {this.state.contract.yourName}
            </div>
          </div>
        </div>
        <div className="contract__nav contract__nav--bottom">
          <ul className="social">
            <li>
              <div className="contract__social">
                <a
                  className="social__link social__link--fb"
                  id="share-fb"
                  target="_blank"
                  href={share_fb_url}
                  title="Share to Facebook"
                  onclick="window._trackEvent('contract', 'social', 'facebook')"
                >
                  <img src={facebookShareImage} alt="Facebook" />
                </a>
                <a
                  className="social__link social__link--twitter"
                  id="share-twitter"
                  target="_blank"
                  href={share_twitter_url}
                  title="Share to Twitter"
                  onclick="window._trackEvent('contract', 'social', 'twitter')"
                >
                  <img src={twitterShareImage} alt="Twitter" />
                </a>

                <a
                  className="social__link social__link--copy"
                  id="share-copy"
                  href="#"
                  title="Copy to clipboard"
                  onClick={this.copy}
                >
                  <img src={copyShareImage} alt="Twitter" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Contract;
